import { fetchCurrentUser } from '../../ducks/user.duck';

// Action types
const SAVE_SHIPPING_DETAILS_REQUEST = 'app/ShippingDetailsPage/SAVE_SHIPPING_DETAILS_REQUEST';
const SAVE_SHIPPING_DETAILS_SUCCESS = 'app/ShippingDetailsPage/SAVE_SHIPPING_DETAILS_SUCCESS';
const SAVE_SHIPPING_DETAILS_ERROR = 'app/ShippingDetailsPage/SAVE_SHIPPING_DETAILS_ERROR';

// Reducer
const initialState = {
  saveInProgress: false,
  saveError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_SHIPPING_DETAILS_REQUEST:
      return { ...state, saveInProgress: true, saveError: null };
    case SAVE_SHIPPING_DETAILS_SUCCESS:
      return { ...state, saveInProgress: false };
    case SAVE_SHIPPING_DETAILS_ERROR:
      return { ...state, saveInProgress: false, saveError: payload };
    default:
      return state;
  }
}

// Action creators
const saveShippingDetailsRequest = () => ({ type: SAVE_SHIPPING_DETAILS_REQUEST });
const saveShippingDetailsSuccess = () => ({ type: SAVE_SHIPPING_DETAILS_SUCCESS });
const saveShippingDetailsError = error => ({
  type: SAVE_SHIPPING_DETAILS_ERROR,
  payload: error,
});

// Thunk
export const saveShippingDetails = values => (dispatch, getState, sdk) => {
  dispatch(saveShippingDetailsRequest());

  return sdk.currentUser
    .updateProfile(
      { privateData: values.privateData },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      dispatch(saveShippingDetailsSuccess());
      return response;
    })
    .catch(e => {
      dispatch(saveShippingDetailsError(e));
      throw e;
    });
};

// Load data function
export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};